footer {
    position: absolute;
    bottom: 0;
    /* width: 90%; */
    height: 4vh;
    text-align: center;
  }
  
  span.copy {
    color: #333;
  }
